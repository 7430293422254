import React from "react";
import Layout from "../components/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../components/reusable/Seo";
import {
  AboutSection,
  AcademySection,
  CompanyBSection,
  ContactSection,
  HeroInfo,
  ImpactSection,
  InfoSection,
} from "../styles/pages/impact";
import lapiezaItem1 from "../images/impact/lapieza-1.png";
import lapiezaItem2 from "../images/impact/lapieza-2.png";
import lapiezaItem3 from "../images/impact/lapieza-3.png";
import lapiezaItem4 from "../images/impact/lapieza-4.png";
import lapiezaItem5 from "../images/impact/lapieza-5.png";
import objetivo1 from "../images/impact/objetivo-1.png";
import objetivo2 from "../images/impact/objetivo-2.png";
import objetivo3 from "../images/impact/objetivo-3.png";
import objetivo4 from "../images/impact/pol-carbon-neutral.png";
import { useTranslation } from "react-i18next";
import { Bg, Text } from "../components/ui";
import { Stack, Button } from "@mui/material";

const Pledge = () => {
  const { t } = useTranslation("impact");

  const itemsReclaimed = React.useMemo(
    () => [
      {
        title: t("aboutUsItemTitle1"),
        description: t("aboutUsItemDescription1"),
        image:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Flogos%2FPiezaYellowFirst.png?alt=media&token=bd84212a-c66d-4737-961c-920ef4cf7b0f",
        id: "1",
      },
      {
        title: t("aboutUsItemTitle2"),
        description: t("aboutUsItemDescription2"),
        image:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Flogos%2FPiezaBlueSecond.png?alt=media&token=979d3b03-e366-47ea-900b-72cea1740b49",
        id: "2",
      },
      {
        title: t("aboutUsItemTitle3"),
        description: t("aboutUsItemDescription3"),
        image:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Flogos%2FPiezaYellowThird.png?alt=media&token=cfdd92b0-3f3d-4ca6-b09f-c0c3a1452f0e",
        id: "3",
      },
    ],
    [t]
  );

  const items = React.useMemo(
    () => [
      {
        title: t("impactItemTitle1"),
        image: lapiezaItem1,
        description: t("impactItemDescription1"),
        id: "a",
      },
      {
        title: t("impactItemTitle2"),
        image: lapiezaItem2,
        description: t("impactItemDescription2"),
        id: "b",
      },
      {
        title: t("impactItemTitle3"),
        image: lapiezaItem3,
        description: t("impactItemDescription3"),
        id: "c",
      },
      {
        title: t("impactItemTitle4"),
        image: lapiezaItem4,
        description: t("impactItemDescription4"),
        id: "d",
      },
      {
        title: t("impactItemTitle5"),
        image: lapiezaItem5,
        description: t("impactItemDescription5"),
        id: "e",
      },
    ],
    [t]
  );

  const ButtonMemo = React.useMemo(
    () => (
      <Button
        href=""
        sx={{
          width: "170px",
        }}
      >
        {t("seeMore")}
      </Button>
    ),
    [t]
  );

  const containerText = React.useCallback(
    (title, description, description2) => (
      <Stack spacing={3} maxWidth="453px">
        <Text.Title textAlign="start">{title}</Text.Title>
        <Text.Body textAlign="start" fontSize="1.8rem">
          {description}
        </Text.Body>
        <Text.Body textAlign="start" fontSize="1.8rem">
          {description2}
        </Text.Body>
        {ButtonMemo}
      </Stack>
    ),
    [ButtonMemo]
  );

  return (
    <>
      <Seo
        canonical="https://empresas.lapieza.io/impact"
        title="Comprometidos con reducir la huella de carbono digital."
        description="En LaPieza, compartimos nuestra ambición para ayudar a otros a acelerar su progreso, comenzando por el impacto de la huella de carbono digital."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Layout>
        <Bg.Image
          opacity={0.3}
          img="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fimpact%2Fteam.png?alt=media&token=ac5aecdd-236c-41a6-a6d2-e539d65d2bc8"
        >
          <Stack
            height="350px"
            padding="12rem 80px 8rem 100px"
            justifyContent="center"
            alignItems="start"
          >
            <Text.Title
              fontSize="3rem"
              texAlign="start"
              isH1
              text={t("heroTitle")}
              color="primary.white"
            />
          </Stack>
        </Bg.Image>
        <Bg.Linear>
          <HeroInfo>
            <div className="main-landing-container-column">
              <Stack maxWidth="76rem" spacing={3}>
                <Text.Title textAlign="start">{t("heroSubtitle")}</Text.Title>
                <Text.Body textAlign="start" fontSize="1.8rem">
                  {t("heroDescription")}
                </Text.Body>
                <Text.Body textAlign="start" fontSize="1.8rem">
                  {t("heroDescription2")}
                </Text.Body>
              </Stack>
              <div
                className="hero-info-footer"
                style={{
                  maxWidth: "1080px",
                }}
              >
                <div className="hero-info-footer-sign">
                  <Stack position="relative" height="80px">
                    <figure
                      style={{
                        position: "absolute",
                        left: "-30px",
                      }}
                    >
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fimpact%2FFirma.png?alt=media&token=07714f9a-4c94-47b9-b2a3-de6ae12501ce"
                        alt="Sign"
                      />
                    </figure>
                  </Stack>
                  <Stack>
                    <Text.Body
                      textAlign="start"
                      sx={{
                        fontWeight: "800",
                      }}
                    >
                      {t("pol")}
                    </Text.Body>
                    <Text.Body textAlign="start">{t("CEO")}</Text.Body>
                  </Stack>
                </div>
                <div className="hero-info-footer-images">
                  <figure>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Flogos%2FEmpresaB.png?alt=media&token=80aa3d0a-a974-42fe-a2ed-1b7cb68e5c5a"
                      alt="Empresa B"
                    />
                  </figure>
                  <figure>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Flogos%2FCarbom.png?alt=media&token=92a03695-fdb5-4b6d-93a7-6a0151947770"
                      alt="Carbon Neutral"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </HeroInfo>
        </Bg.Linear>
        <AboutSection>
          <div className="about-section main-landing-container-column">
            <Text.Title
              textAlign="start"
              sx={{
                mb: 2,
              }}
            >
              {t("aboutUsTitle")}
            </Text.Title>
            <Text.Body
              sx={{
                maxWidth: "700px",
                mb: 5,
              }}
              textAlign="start"
              fontSize="1.8rem"
            >
              {t("aboutUsDescription")}
            </Text.Body>
            <div className="about-list">
              {itemsReclaimed.map((item) => (
                <article key={item.id}>
                  <figure>
                    <img src={item.image} alt="Ilustración de LaPieza" />
                  </figure>
                  <Stack spacing={2}>
                    <Text.Title fontSize="1.6rem" textAlign="start">
                      {item.title}
                    </Text.Title>
                    <Text.Body fontSize="1.8rem" textAlign="start">
                      {item.description}
                    </Text.Body>
                  </Stack>
                </article>
              ))}
            </div>
            <div className="about-footer">
              <Text.Title
                textAlign="start"
                sx={{
                  maxWidth: "250px",
                }}
              >
                {t("aboutUsFooterTitle")}
              </Text.Title>
              <div className="about-nums">
                <article>
                  <Stack>
                    <Text.Title textAlign="start" fontSize="6rem">
                      {t("aboutUsFooterItemTitle1")}
                    </Text.Title>
                    <Text.Body
                      textAlign="start"
                      fontSize="1.8rem"
                      sx={{
                        maxWidth: "250px",
                      }}
                    >
                      {t("aboutUsFooterItemDescription1")}
                    </Text.Body>
                  </Stack>
                </article>
                <hr />
                <article>
                  <Stack>
                    <Text.Title textAlign="start" fontSize="6rem">
                      {t("aboutUsFooterItemTitle2")}
                    </Text.Title>
                    <Text.Body
                      textAlign="start"
                      fontSize="1.8rem"
                      sx={{
                        maxWidth: "250px",
                      }}
                    >
                      {t("aboutUsFooterItemDescription2")}
                    </Text.Body>
                  </Stack>
                </article>
              </div>
            </div>
          </div>
        </AboutSection>
        <Bg.Linear>
          <AcademySection>
            <div className="main-landing-container-row">
              <figure>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Flogos%2FComputers.png?alt=media&token=06b89fc5-ac4d-467e-adb7-4acb321057c2"
                  alt="Academy"
                />
              </figure>
              <article>
                <Stack spacing={3}>
                  <Stack width="100px" height="30px">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Flogos%2FLogoAcademy.png?alt=media&token=737bcfd8-00c1-4199-a166-04ee6caa6627"
                      alt="Academy"
                    />
                  </Stack>
                  <Text.Title textAlign="start">{t("academyTitle")}</Text.Title>
                  <Text.Body textAlign="start" fontSize="1.8rem">
                    {t("academyDescription")}
                  </Text.Body>
                  <Button
                    href="https://academy.lapieza.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      width: "170px",
                    }}
                  >
                    {t("seeMore")}
                  </Button>
                </Stack>
              </article>
            </div>
          </AcademySection>
        </Bg.Linear>
        <ImpactSection>
          <div className="impact-section main-landing-container-column">
            <Stack spacing={3} mb={3}>
              <Text.SubTitle fontSize="1.8rem">
                {t("impactTitle")}
              </Text.SubTitle>
              <Text.Title>{t("impactSubtitle")}</Text.Title>
              <Text.Body
                fontSize="1.8rem"
                sx={{
                  maxWidth: "600px",
                }}
              >
                {t("impactDescription")}
              </Text.Body>
            </Stack>
            <div className="impact-list">
              {items.map((item) => (
                <article key={item?.id}>
                  <figure>
                    <img src={item?.image} alt="Ilustración de LaPieza" />
                  </figure>
                  <Stack spacing={4}>
                    <Text.Title fontSize="1.8rem">{item?.title}</Text.Title>
                    <Text.Body fontSize="1.8rem">{item.description}</Text.Body>
                  </Stack>
                </article>
              ))}
            </div>
          </div>
        </ImpactSection>
        <Bg.Gradient>
          <CompanyBSection>
            <div className="main-landing-container-column">
              <div className="company-b-card">
                <figure>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Flogos%2FEmpresaBWhitPiezas.png?alt=media&token=49aeece3-1c25-439a-8543-e1d275fdb519"
                    alt="Empresa B"
                  />
                </figure>
                <article>
                  <Stack maxWidth="486px">
                    <Text.Title fontSize="4rem" textAlign="start">
                      {t("companyBTitle")}
                    </Text.Title>
                    <Text.Body textAlign="start" fontSize="3rem">
                      {t("companyBSubtitle")}
                    </Text.Body>
                    <Stack spacing={3} mt={2}>
                      <Text.Body textAlign="start" fontSize="1.6rem">
                        {t("companyBDescription1")}
                      </Text.Body>
                      <Text.Body textAlign="start" fontSize="1.6rem">
                        {t("companyBDescription2")}
                      </Text.Body>
                    </Stack>
                  </Stack>
                </article>
              </div>
            </div>
          </CompanyBSection>
        </Bg.Gradient>
        <InfoSection>
          <div className="info-section main-landing-container-column">
            <div className="info-section-item info-section-item-one">
              <article>
                {containerText(
                  t("infoTitle1"),
                  t("infoDescription1"),
                  t("infoMessage1")
                )}
              </article>
              <figure>
                <img src={objetivo1} alt="Objetivos LaPieza" />
              </figure>
            </div>
            <div className="info-section-item info-section-item-two">
              <figure>
                <img src={objetivo2} alt="AIESEC LaPieza" />
              </figure>
              <article>
                {containerText(
                  t("infoTitle2"),
                  t("infoDescription2"),
                  t("infoMessage2")
                )}
              </article>
            </div>
            <div className="info-section-item info-section-item-one">
              <article>
                {containerText(
                  t("infoTitle3"),
                  t("infoDescription3"),
                  t("infoMessage3")
                )}
              </article>
              <figure>
                <img src={objetivo3} alt="Pledge LaPieza" />
              </figure>
            </div>
            <div className="info-section-item info-section-item-two">
              <figure>
                <img src={objetivo4} alt="Carbon Neutral LaPieza" />
              </figure>
              <article>
                {containerText(t("infoTitle4"), t("infoDescription4"))}
              </article>
            </div>
          </div>
        </InfoSection>
        <Bg.Linear>
          <ContactSection>
            <div className="contact-section main-landing-container-row">
              <article>
                <Stack spacing={3}>
                  <Text.Title fontSize="3.8rem" textAlign="start">
                    {t("contactTitle")}
                  </Text.Title>
                  <Text.Body textAlign="start" fontSize="1.8rem">
                    {t("contactDescription")}
                  </Text.Body>
                  <Button
                    sx={{
                      width: "280px",
                    }}
                  >
                    <Link
                      style={{
                        color: "white",
                        textDecoration: "none",
                      }}
                      to="/contact"
                    >
                      {t("contactTeam")}
                    </Link>
                  </Button>
                </Stack>
              </article>
              <figure>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Flogos%2FquestionPiece.png?alt=media&token=76fcbb68-a4ed-4439-be09-656a03417c91"
                  alt="Neutralidad de carbono | LaPieza"
                />
              </figure>
            </div>
          </ContactSection>
        </Bg.Linear>
      </Layout>
    </>
  );
};

export default Pledge;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
